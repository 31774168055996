import {type AppError, type Result} from '@packages/utils';
import {useQuery, type UseQueryOptions} from '@tanstack/react-query';
import {type z} from 'zod';
import {
  AdvertisementMypageJackBannerSettingSchema,
  AdvertisementUserSchema,
  getBannerSchema,
  getBannerZSchema,
  getJackBannerSchema,
} from '../entities/advertisement/schema';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {defaultClient} from '../../lib/defaultClient';
import {createPathWithQuery} from '../../shared/services/createPathWithQuery';

export type AdvertisementUserReturnValueType = Result<
  AdvertisementUserSchema,
  AppError
>;
export type AdvertisementUserSchema = z.infer<typeof AdvertisementUserSchema>;

type GetAdvertisementUserArgs = {
  version?: string;
  accessToken: string;
};

type UseGetAdvertisementUserOptions = {
  config?: UseQueryOptions<AdvertisementUserReturnValueType>;
} & GetAdvertisementUserArgs;

export const getAdvertisementUser = async ({
  version = DEFAULT_API_VERSION,
  accessToken,
}: GetAdvertisementUserArgs): Promise<AdvertisementUserReturnValueType> => {
  if (accessToken) {
    defaultClient.setToken(accessToken);
  }

  const path = `/${version}/advertisements/user`;
  const result = await defaultClient.get(path, AdvertisementUserSchema);
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export const useGetAdvertisementUser = ({
  config,
  accessToken,
}: UseGetAdvertisementUserOptions) => {
  return useQuery<AdvertisementUserReturnValueType>({
    ...config,
    queryKey: ['advertisementUser'],
    async queryFn() {
      return getAdvertisementUser({accessToken});
    },
  });
};

export type GetBannerSchema = z.infer<typeof getBannerSchema>;

type ResultGetBannerSchema = Result<GetBannerSchema, AppError>;

type GetBannerArgs = {
  version?: string;
  accessToken?: string;
};

export const getBanner = async ({
  version = DEFAULT_API_VERSION,
  accessToken,
}: GetBannerArgs): Promise<ResultGetBannerSchema> => {
  if (accessToken) {
    defaultClient.setToken(accessToken);
  }

  const result = await defaultClient.get(
    `/${version}/advertisements/banners`,
    getBannerSchema,
  );
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UseGetBannerOptions = {
  config?: UseQueryOptions<ResultGetBannerSchema>;
} & GetBannerArgs;

export const useGetBanner = ({config, accessToken}: UseGetBannerOptions) => {
  return useQuery<ResultGetBannerSchema>({
    ...config,
    queryKey: ['banners'],
    async queryFn() {
      return getBanner({
        accessToken,
      });
    },
  });
};

export type GetBannerZSchema = z.infer<typeof getBannerZSchema>;
type ResultGetBannerZSchema = Result<GetBannerZSchema, AppError>;

type GetBannerZArgs = {
  accessToken: string;
  version?: string;
};

export const getBannerZ = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
}: GetBannerZArgs): Promise<ResultGetBannerZSchema> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.get(
    `/${version}/advertisements/banner-z`,
    getBannerZSchema,
  );
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UseGetBannerZOptions = {
  config?: UseQueryOptions<ResultGetBannerZSchema>;
} & GetBannerZArgs;

export const useGetBannerZ = ({accessToken, config}: UseGetBannerZOptions) => {
  return useQuery<ResultGetBannerZSchema>({
    ...config,
    queryKey: ['bannerZ'],
    async queryFn() {
      return getBannerZ({accessToken});
    },
  });
};

export type GetJackBannerSchema = z.infer<typeof getJackBannerSchema>;
type ResultGetJackBannerSchema = Result<GetJackBannerSchema, AppError>;

type GetJackBannerArgs = {
  accessToken: string;
  version?: string;
};

export const getJackBanner = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
}: GetJackBannerArgs): Promise<ResultGetJackBannerSchema> => {
  defaultClient.setToken(accessToken);
  const result = await defaultClient.get(
    `/${version}/advertisements/jack-banners`,
    getJackBannerSchema,
  );
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

type UseGetJackBannerOptions = {
  config?: UseQueryOptions<ResultGetJackBannerSchema>;
} & GetJackBannerArgs;

export const useGetJackBanner = ({
  accessToken,
  config,
}: UseGetJackBannerOptions) => {
  return useQuery<ResultGetJackBannerSchema>({
    ...config,
    queryKey: ['jackBanners'],
    async queryFn() {
      return getJackBanner({accessToken});
    },
  });
};

type GetMypageJackBannerSettingArgs = {
  accessToken: string;
  queryParams: {
    is_mobile: boolean;
  };
  version?: string;
};

export const getMypageJackBannerSetting = async ({
  accessToken,
  queryParams,
  version = DEFAULT_API_VERSION,
}: GetMypageJackBannerSettingArgs): Promise<ResultGetMypageJackBannerSetting> => {
  defaultClient.setToken(accessToken);

  const path = createPathWithQuery(
    `/${version}/advertisements/mypage-jack-banner-setting`,
    {...queryParams},
  );
  const result = await defaultClient.get(
    path,
    AdvertisementMypageJackBannerSettingSchema,
  );
  if (!result?.ok) {
    throw new Error(result.error);
  }

  return result;
};

export type GetMypageJackBannerSettingResponse = z.infer<
  typeof AdvertisementMypageJackBannerSettingSchema
>;
export type ResultGetMypageJackBannerSetting = Result<
  GetMypageJackBannerSettingResponse,
  AppError
>;

type UseGetMypageJackBannerSetting = {
  config?: UseQueryOptions<ResultGetMypageJackBannerSetting>;
} & GetMypageJackBannerSettingArgs;

export const useGetMypageJackBannerSetting = ({
  version = DEFAULT_API_VERSION,
  accessToken,
  queryParams,
  config,
}: UseGetMypageJackBannerSetting) => {
  return useQuery<ResultGetMypageJackBannerSetting>({
    ...config,
    queryKey: ['getMypageJackBannerSetting', queryParams.is_mobile],
    async queryFn() {
      return getMypageJackBannerSetting({accessToken, queryParams, version});
    },
  });
};
