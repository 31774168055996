import {
  type UseInfiniteQueryResult,
  useInfiniteQuery,
  useQuery,
  type UseQueryOptions,
} from '@tanstack/react-query';
import {useState} from 'react';
import {z} from 'zod';
import {type AppError} from '@packages/utils';
import {DEFAULT_API_VERSION} from '../../config/apiVersion';
import {
  GetNotificationsResponseSchema,
  type GetNotificationsResponseType,
  responseSchema,
} from '../entities/notifications/schema';
import {defaultClient} from '../../lib/defaultClient';
import {createPathWithQuery} from '../../shared/services/createPathWithQuery';

type UseNotificationsCountsArg = {
  config?: UseQueryOptions<GetNotificationsCountsResponse>;
} & GetNotificationsCountsArg;

export type GetNotificationsCountsResponse = z.infer<typeof responseSchema>;

export type GetNotificationsCountsArg = {
  accessToken: string;
  version?: string;
};

export const getNotificationsCounts = async ({
  accessToken,
  version = DEFAULT_API_VERSION,
}: GetNotificationsCountsArg) => {
  defaultClient.setToken(accessToken);
  const response = await defaultClient.get(
    `/${version}/notifications/counts`,
    responseSchema,
  );

  if (!response.ok) {
    throw new Error(response.error);
  }

  return response.value;
};

export const useNotificationsCounts = ({
  config,
  ...args
}: UseNotificationsCountsArg) => {
  const [favoritedCompany, setFavoritedCompany] = useState(0);

  const {isLoading} = useQuery<GetNotificationsCountsResponse>(
    ['useNotificationsCounts', args],
    async () => getNotificationsCounts(args),
    {
      ...config,
      onSuccess(data) {
        setFavoritedCompany(data.favoritedCompany);
      },
    },
  );

  return {
    counts: {
      favoritedCompany,
    },
    isLoading,
  };
};

export type Notification = {
  id: number;
  title: string;
  body: string;
  detailURL: string;
  isUnread: boolean;
  eventType: string;
  createdAt: string;
};

type GetNotificationsArg = {
  page?: number;
  accessToken: string;
  forAllUsers: boolean;
  categories?: string[];
  withoutCategories?: string[];
  limit?: number;
  version?: string;
};

export type NotificationResponse = {
  totalCount: number;
  nextPage: number;
  notifications: Notification[];
};

// ログイントップ画面通知取得用
export const getLoggedInTopNotifications = async ({
  page = 1,
  accessToken,
  limit = 20,
  forAllUsers,
  categories = [],
  withoutCategories = [],
  version = DEFAULT_API_VERSION,
}: GetNotificationsArg) => {
  defaultClient.setToken(accessToken);
  const params = {
    page,
    limit,
    forAllUsers,
    categories,
    withoutCategories,
  };
  const path = createPathWithQuery(
    `/${version}/notifications/list`,
    params,
    'after',
  );
  const response = await defaultClient.get(
    path,
    GetNotificationsResponseSchema,
  );

  if (!response.ok) {
    throw new Error(response.error);
  }

  return response.value;
};

type PutNotificationOpenArg = {
  notificationId: number;
  accessToken: string;
  allPast?: boolean;
  version?: string;
};

const PutNotificationOpen = z.object({});

// 指定IDのみの既読処理 PUT /notifications/{notificationId}/open
// 全ての通知一覧の既読処理 PUT /notifications/{notificationId}/open?allPast=true (notificationIdは最新の通知IDを指定)
export const putReadNotification = async ({
  notificationId,
  accessToken,
  allPast = false,
  version = DEFAULT_API_VERSION,
}: PutNotificationOpenArg) => {
  defaultClient.setToken(accessToken);
  const response = await defaultClient.put(
    `/${version}/notifications/${notificationId}/open`,
    PutNotificationOpen,
    {
      allPast,
    },
  );

  if (!response.ok) {
    throw new Error(response.error);
  }

  return response;
};

type UseNotificationsArg = {
  config?: UseQueryOptions<GetNotificationsResponseType>;
} & GetNotificationsArg;

export const useInfiniteNotifications = ({
  config,
  ...args
}: UseNotificationsArg): UseInfiniteQueryResult<
  GetNotificationsResponseType,
  AppError
> => {
  return useInfiniteQuery(
    ['notifications'],
    async ({pageParam = 1}) => {
      const result = await getLoggedInTopNotifications({
        ...args,
        page: pageParam,
      });
      return result;
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage ?? 0,
    },
  );
};

export const useInfiniteFollowCompanyNotifications = ({
  config,
  ...args
}: UseNotificationsArg): UseInfiniteQueryResult<
  GetNotificationsResponseType,
  AppError
> => {
  return useInfiniteQuery(
    ['followCompanyNotifications'],
    async ({pageParam = 1}) => {
      const result = await getLoggedInTopNotifications({
        ...args,
        page: pageParam,
      });
      return result;
    },
    {
      getNextPageParam: (lastPage) => lastPage.nextPage ?? 0,
    },
  );
};
