import {Box, Text} from '@mantine/core';
import type {FC} from 'react';
import {MdWarning} from 'react-icons/md';
import {useUsersMe} from '../../core/repositories/usersMeRepository';
import {useAuth} from '../../core/usecases/useAuth';
import {CommonLink} from './CommonLink';

export const EmailConfirmNoticeForPC: FC = () => {
  const {accessToken} = useAuth();
  const {data: usersMe} = useUsersMe({
    config: {enabled: Boolean(accessToken)},
    accessToken,
  });

  return usersMe?.ok && !usersMe.value.isEmailConfirmed ? (
    <Box className="mb-[10px] flex h-[53px] items-center justify-center bg-background-4 text-[22px] font-bold">
      <MdWarning size={24} className="text-notification-1" />
      <Text className="mr-2">
        いつも使うメールアドレスが認証されていません。
      </Text>
      <CommonLink href="/mypage/profile" className="text-link-1 underline">
        認証について
      </CommonLink>
    </Box>
  ) : null;
};
